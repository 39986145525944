import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const FirmNotification = () => {
  const firm = useEoValue(FIRM_SELECTOR)

  if (!firm || !firm.notification) {
    return null
  }

  return (
    <Box
      id='firm_notification'
      component='p'
      sx={{
        backgroundColor: firm.notification.bgColor,
        color: firm.notification.textColor,
        p: 2,
        my: 2,
        whiteSpace: 'pre-line'
      }}
    >
      {firm.notification.message}
    </Box>
  )
}

export default FirmNotification
